<template>
    <div class="flex-center container message" v-if="nowUserCanLoginOnWpPlugin">
        <div>
            <div>Ваша учетная запись успешно активирована.
                <br>Сейчас Вы будете перенаправлены на страницу установки плагина Wordpress.
                <div class="loader-wrapper">
                    <BaseLoader color="#e8703b"></BaseLoader>
                    <span>{{timeToRedirect}}</span>
                </div>
                Если этого не произошло воспользуйтесь ссылкой:
                <a :href="$store.state.static.wp_plugin_link">{{$store.state.static.wp_plugin_name}}</a>
            </div>
            <br>
            <p>По любым вопросам обращайтесь в службу поддержки </p>
            <a :href="`mailto:${$store.state.static.contact_email}`">{{$store.state.static.contact_email}}</a>
        </div>
    </div>
    <div class="flex-column message container" v-else-if="message">
        <p>Ссылка активации не действительна.</p><p>По любым вопросам обращайтесь в службу поддержки </p><a :href="`mailto:${$store.state.static.contact_email}`">{{$store.state.static.contact_email}}</a>
    </div>
    <BaseLoader v-else
                color="#e8703b"
                :height="80"
                :width="80">
    </BaseLoader>
</template>

<script>
    import { mapActions } from 'vuex';
    export default {
        name: "RegistrationActivateScreen",
        data(){
            return {
                message: '',
                timeToRedirect: 10,
                nowUserCanLoginOnWpPlugin: false
            }
        },
        mounted(){
            this.ACTIVATE_LOGIN({
                hash: this.$route.params.hash
            })
            .then((response)=>{
                if (response && response.access_token) {
                    if (response.user_type === 2) {
                        this.GET_USER_PUBLISHER_INFO()
                            .then(()=>{
                                this.$router.push(this.$store.state.static.routePaths.dashboardp);
                            })
                            .catch(()=> {
                                this.$router.push(this.$store.state.static.routePaths.dashboardp);
                            })
                    } else {
                        this.GET_USER_INFO()
                            .then(()=>{
                                this.$router.push(this.$store.state.static.routePaths.dashboard);
                            })
                            .catch(()=> {
                                this.$router.push(this.$store.state.static.routePaths.dashboard);
                            })
                    }
                } else {
                    this.nowUserCanLoginOnWpPlugin = true;
                    this.setTimerToRedirect();
                }
            })
            .catch(()=>{
                this.message = true
            })
        },
        methods: {
            ...mapActions(['GET_USER_INFO','GET_USER_PUBLISHER_INFO','ACTIVATE_LOGIN']),
            setTimerToRedirect(){
                setInterval(() => {
                   this.timeToRedirect--;
                   if (this.timeToRedirect <= 0){
                       location.href = this.$store.state.static.wp_plugin_link;
                   }
                }, 1000)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .message {
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 24px;
        text-align: center;
        @media (max-width: 768px) {
            font-size: 14px;
            text-align: center;
        }
        a {
            color: #e8703b;
            text-decoration: underline;
        }
    }
    .loader-wrapper{
        position: relative;
        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 20px;
            color: #e8703b;
            font-weight: 500;
            @media (max-width: 768px) {
                font-size: 14px;
            }
        }

    }
</style>
