<template>
    <div class="flex-center container">
        <div class="check-yor-email flex-column" v-if="needConfirmFromEmail">
            <p>На ваш e-mail была отправлена ссылка с подтверждением.
                <br>Ссылка будет действительна в течении 24 часов
            </p>
            <div class="loader-wrapper">
                <BaseLoader color="#e8703b"></BaseLoader>
                <span>{{timeToRedirect}}</span>
            </div>
            <p>
                По любым вопросам обращайтесь в службу поддержки <br>
                <a :href="`mailto:${$store.state.static.contact_email}`">{{$store.state.static.contact_email}}</a>
            </p>
        </div>
        <div v-else class="registration-page flex-column">
            <header class="flex-center flex-between">
                <h1>Регистрация</h1>
                <div class="select-user-type flex">
                    <BaseButton class="btn-default"
                                v-for="(userType, index) in userTypes"
                                :key="index"
                                :class="{'btn-filled': activeTab === userType}"
                                @click="setUserType(userType, index)">
                        {{tabsNames[userType]}}
                    </BaseButton>
                </div>
            </header>
            <component :is="currentRegistrationComponent" @needConfirmFromEmail="needConfirmFromEmail = true"></component>
            <footer class="flex-center">
                <img :src="`${staticDomain}/img/headphones.png`" alt="">
                <p>По любым вопросам обращайтесь в службу поддержки <a :href="`mailto:${$store.state.static.contact_email}`">{{$store.state.static.contact_email}}</a></p>
            </footer>
        </div>
    </div>

</template>

<script>
    import BloggerRegistrationComponent from "../components/authorisation/BloggerRegistrationComponent";
    import PublisherRegistrationComponent from "../components/authorisation/PublisherRegistrationComponent";

    export default {
        name: "RegistrationScreen",
        data(){
            return {
                needConfirmFromEmail: false,
                activeTab: null,
                userType: 1,
                userTypes: ['blogger', 'webmaster'],
                tabsNames:{
                    blogger: 'Блогер',
                    webmaster: 'Вебмастер',
                },
                timeToRedirect: 10,
                staticDomain: this.$store.state.static.staticDomain,
            }
        },
        methods: {
            setUserType(name, index){
                this.activeTab = name;
                this.userType = index + 1;
            },
            checkTimeToRedirect(){
                this.timeToRedirect--;
                if (this.timeToRedirect <= 0){
                    this.$router.push('/');
                }
            }
        },
        cron:{
            time: 1000,
            method: 'checkTimeToRedirect',
            autoStart: false
        },
        watch:{
            needConfirmFromEmail(val){
                val ? this.$cron.start('checkTimeToRedirect') : null;
            }
        },
        mounted(){
            this.activeTab =  this.tabsNames[this.$route.params[1]] ? this.$route.params[1] : 'blogger';
        },
        computed: {
            currentRegistrationComponent: function () {
                return this.activeTab === 'blogger' ? BloggerRegistrationComponent : PublisherRegistrationComponent;
            },
        },
        components: {
            BloggerRegistrationComponent,
            PublisherRegistrationComponent,
        }
    }
</script>

<style lang="scss" scoped>
    .registration-page {
        width: 100%;
        max-width: 1140px;
        margin: 0 auto;
        height: 535px;
        min-height: 535px;
        border: solid 1px #d0d0d0;
        color: #424242;
        justify-content: space-between;
        position: relative;

        @media (max-width: 1400px) {
            height: auto;
        }
        h1 {
            font-size: 36px;
            @media (max-width: 600px) {
                display: none;
            }
        }
        header {
            width: 100%;
            border-bottom: solid 3px #e8e8e8;
            padding: 0 30px;
            flex-wrap: wrap;
            min-height: 110px;
            height: 110px;
            @media (max-width: 900px) {
                padding: 0 15px;
            }
            @media (max-width: 600px) {
                justify-content: center;
                min-height: 60px;
                height: 60px;
                .btn {
                    height: 44px;
                }
            }
        }
        footer {
            width: 100%;
            align-self: flex-end;
            height: 110px;
            padding: 0 30px;
            font-size: 16px;
            font-weight: 500;
            border-top: solid 3px #e8e8e8;
            text-align: center;

            @media (max-width: 1400px) {
                margin-top: 20px;
            }
            @media (max-width: 900px) {
                padding: 0 15px;
            }
            img {
                width: 90px;
                @media (max-width: 768px) {
                    display: none;
                }
            }
            a {
                color: #e8703b;
                /*padding-left: 10px;*/
                text-decoration: underline;
            }
        }
    }

    .check-yor-email{
        color: #424242;
        font-size: 22px;
        font-weight: bold;
        justify-content: center;
        text-align: center;
        max-height: 90vh;
        height: 90vh;
        min-height: auto;
        a {
            color: #e8703b;
            text-decoration: underline;
        }
        @media (max-width: 600px) {
            font-size: 16px;
        }
    }
    .loader-wrapper{
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 20px;
            color: #e8703b;
            font-weight: 500;
            @media (max-width: 768px) {
                font-size: 14px;
            }
        }

    }
</style>
