<template>
    <section class="flex-between ">
        <AuthFormErrorComponent :error="mainError" :additionalParams="additionalParamsToErrormessage"></AuthFormErrorComponent>
        <div class="fields left-field">
            <div class="flex-center inputs" v-for="(field, index) in fields.left" :key="index">
                <span>{{field.title}} <i v-if="field.required">*</i></span>
                <BaseInput
                        :error="errors[field.name]"
                        v-model="formData[field.name]"
                        :maxlength="maxlength">
                </BaseInput>
            </div>
        </div>
        <div class="fields right-field">
            <div class="fields left-field">
                <div class="flex-center inputs" v-for="(field, index) in fields.right" :key="index">
                    <span>{{field.title}} <i v-if="field.required">*</i></span>
                    <BaseInput
                            :error="errors[field.name]"
                            v-model="formData[field.name]"
                            type="password"
                            isPassword="true">
                    </BaseInput>
                </div>
            </div>
            <div class="flex-end submit-btn">
                <BaseButton class='btn-primary'
                            :processing="loading"
                            @click="registration">Зарегистрироваться</BaseButton>
            </div>
        </div>
    </section>
</template>

<script>
    import _ from 'lodash';
    import { mapActions } from 'vuex';
    import AuthFormErrorComponent from "./AuthFormErrorComponent";

    export default {
        name: "RegistrationFormComponent.vue",
        props: ['userType','fields','dataForRequest','errors'],
        data(){
            return {
                maxlength : 191,
                loading: false,
                baseFormData: {
                    email: '',
                    password: '',
                    passwordConfirm: '',
                },
                baseRequestParams: {
                    email: '',
                    phone: '',
                    password: '',
                },
                mainError: '',
                additionalParamsToErrormessage: null,
                errorText : {
                    emptyPlace: 'Все поля должны быть заполнены',
                    notValidEmail: 'Введите корректный e-mail',
                    passwordDontMatch: 'Пароли не совпадают',
                    passwordToSmall: 'Пароль не может быть короче 8 символов',
                    phoneToLong: 'Введите корректный телефон',
                },
                localisationErrors: {
                    email: 'Пользователь с таким email уже зарегистрирован. ',
                    username: 'Пользователь с таким именем уже зарегистрирован. ',
                    phone: 'Пользователь с таким телефоном уже зарегистрирован',
                    url: 'Адрес сайта не правильный',
                    url_has_been_taken: 'Пользователь с таким сайтом уже зарегистрирован. ',
                },
            }
        },
        computed:{
            formData(){
                return Object.assign(this.baseFormData, this.dataForRequest)
            },
            requestParams(){
                return Object.assign( this.baseRequestParams, this.dataForRequest )
            }
        },
        methods: {
            ...mapActions(['DO_REGISTRATION', 'DO_LOGIN']),
            registration(){
                this.setEmptyErrorsForEveryField();

                if (this.hasEmptyField()) {
                    this.mainError = this.errorText.emptyPlace;
                    return;
                }

                if (!this.validateEmail(this.formData.email)) {
                    this.errors.email = true;
                    this.mainError = this.errorText.notValidEmail;
                    return;
                }
                if (this.formData.password.length < 8) {
                    this.errors.password = true;
                    this.mainError = this.errorText.passwordToSmall;
                    return;
                }

                if (this.formData.password !== this.formData.passwordConfirm) {
                    this.mainError = this.errorText.passwordDontMatch;
                    return;
                }

                this.mainError = '';
                this.loading = true;

                this.DO_REGISTRATION(this.getParamsForRequest())
                    .then(()=> {
                        // this.loading = false;
                        this.$emit('needConfirmFromEmail');
                    })
                    .catch((err)=> {
                        // Да тут жесть, но я надеюсь это временное решение. Так как окончательного решения по ошибкам пока не было
                        this.loading = false;

                        if (err.errors && Object.keys(err.errors).length) {
                            this.handleErrorsList(err);
                            return
                        }
                        if (err && err.message) {
                            this.mainError = err.message;
                        }
                    })
            },
            handleErrorsList(err){
                _.each(err.errors, (item, key) => {
                    this.errors[key] = true;

                    switch (key){
                        case 'email':
                            if (item[0] === 'The email has already been taken') {
                                this.mainError+=this.localisationErrors.email + '<br/>';
                                this.createAdditionalErrorParams()
                            } else {
                                this.mainError += this.errorText.notValidEmail + '<br/>';
                            }
                            break;
                        case 'url':
                            if ( item[0] === 'The url has already been taken') { //The url has already been taken.
                                this.mainError+=this.localisationErrors.url_has_been_taken + '<br/>';
                                this.createAdditionalErrorParams();
                            } else {
                                this.mainError += this.localisationErrors.url + '<br/>';
                            }
                            break;
                        default :
                            this.mainError += this.localisationErrors[key]+ '<br/>';
                            break;
                    }
                });
            },
            createAdditionalErrorParams(){
                this.additionalParamsToErrormessage = {
                    path: this.$store.state.static.routePaths.login,
                    pathName: 'login',
                    message: 'Авторизоваться',
                    params: {
                        initEmail: this.formData.email
                    }
                }
            },
            getParamsForRequest(){
                let obj = {
                    user_type: this.userType,
                };

                _.each(this.requestParams, (val, key) =>{
                    obj[key] = this.formData[key]
                });

                return obj
            },
            hasEmptyField(){
                let hasEmpty = false;
                _.each(this.formData, (item, key) => {
                    !item.length && this.isRequired(key) ? hasEmpty = true: null
                });
                return hasEmpty
            },
            setEmptyErrorsForEveryField(){ // Для полей без required === true выведем ошибку на заполнение
                this.additionalParamsToErrormessage = null;
                _.forIn(this.formData, (item, key) => {
                    !item.length && this.isRequired(key) ? this.errors[key] = true : this.errors[key] = false;
                });
            },
            isRequired(prop){ //Проверка есть ли в поле из объекта this.fields свойство required
                let item = _.find(this.fields.left, (val) =>{
                    return val.name === prop
                });

                return !item || item['required']
            },
            validateEmail(text){
                return text.match(/@/g) && text.match(/@/g).length === 1
            },
        },
        components: {
            AuthFormErrorComponent,
        }
    }
</script>

<style lang="scss" scoped>
    section {
        flex-wrap: nowrap;
        width: 100%;
        padding: 0 30px;
        @media (max-width: 1150px) {
            padding: 15px 30px 0;
        }
        @media (max-width: 900px) {
            padding: 15px 15px 0;
        }
        @media (max-width: 600px) {
            flex-wrap: wrap;
        }
        .inputs {
            margin-bottom: 15px;

            @media (max-width: 1150px) {
                flex-wrap: wrap;
                justify-content: flex-start;
                margin-bottom: 0;

                ::v-deep label {
                    input {
                        width: 100% !important;
                    }
                }
            }

            &.bottom-input {
                margin: 0;
            }
            span {
                min-width: 125px;
                color: #424242;
                white-space: nowrap;
                font-weight: bold;
                display: block;
                font-size: 16px;
                i {
                    color: #d75116;
                }
            }
            ::v-deep label {
                margin: 0;
                padding: 0;
                input {
                    width: 380px;
                    margin: 15px 0;
                }
            }
        }

        .left-field {
            @media (max-width: 1150px) {
                padding-right: 5px;
            }
        }
        .right-field {
            @media (max-width: 1150px) {
                padding-left: 5px;
            }
            .inputs {
                span {
                    min-width: 200px;
                }
                ::v-deep label {

                    input {
                        width: 330px;
                    }
                }
            }
        }
        .fields {
            @media (max-width: 600px) {
                width: 100%;
                padding: 0;
            }
        }
        .submit-btn {
            margin-top: 22px;
            @media (max-width: 1150px) {
                margin-top: 39px;
            }
            @media (max-width: 600px) {
                margin-top: 20px;
            }
            ::v-deep .btn {
                height: 51px;
            }

        }
    }
</style>

