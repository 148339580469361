<template>
    <RegistrationFormComponent
            :fields="fields"
            :dataForRequest="dataForRequest"
            :errors="errors"
            @needConfirmFromEmail="$emit('needConfirmFromEmail')"
            :userType="2">
    </RegistrationFormComponent>
</template>

<script>
    import RegistrationFormComponent from "./RegistrationFormComponent";

    export default {
        name: "PublisherRegistrationComponent",

        data(){
            return {
                dataForRequest: {
                    url: '',
                },
                errors: {
                    email: '',
                    url: '',
                    phone: '',
                    password: '',
                    passwordConfirm: '',
                },
                fields: {
                    left: [
                        {name: 'email', title: 'Ваш email', required: true},
                        {name: 'url', title: 'Адрес сайта', required: true},
                        //{name: 'phone', title: 'Телефон', required: false},
                    ],
                    right: [
                        {name: 'password', title: 'Пароль', required: true},
                        {name: 'passwordConfirm', title: 'Подтвердите пароль', required: true},
                    ],
                }
            }
        },
        components: {
            RegistrationFormComponent,
        }
    }
</script>

<style lang="scss" scoped>

</style>

